import { useClearDocumentReferrer } from '@/libs/v2/utils/googleAds';

// Just a component wrapper around the hook since `App` is a class component and
// can't use the hook
const ClearDocumentReferrer = () => {
  useClearDocumentReferrer({ disable: false });

  return null;
};

export default ClearDocumentReferrer;
